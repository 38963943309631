import React from "react";
import { graphql, withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import Img from "gatsby-image";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "./article.scss";

import { getStringDate } from "utils/formats";

import Layout from "components/layout";
import { GeneralOpenGraphMeta } from "components/generalOpenGraphMeta";

import timeIcon from "images/icons/clock-icon.svg";
import cakeIcon from "images/icons/pancake-icon.svg";
import SEO from "components/seo";

const RecipeTemplate = ({ data }) => {
  const { strapiRecipe } = data;
  const {
    title,
    preparationTimeInMinutes,
    difficulty,
    recipeContent,
    thumbnailImage,
    images,
    originallyCreatedAt,
    createdAt,
  } = strapiRecipe;

  const translateDifficulty = (difficultyEn) => {
    switch (difficultyEn) {
      case "easy":
        return "jednoduchá";
      case "medium":
        return "střední";
      case "hard":
        return "obtížná";
    }
  };

  return (
    <Layout>
      <div className="page-article">
        <SEO />
        <Helmet>
          <GeneralOpenGraphMeta />
        </Helmet>
        <SimpleReactLightbox>
          <div className="right-side-container">
            <span className="site-underline"></span>

            <div className="article-photo-container mobile">
              <div className="main-image">
                <div className="image-wrap">
                  <img
                    src={thumbnailImage.childImageSharp.fluid.src}
                    alt="Vygenerovaný alt tag"
                    className="image"
                    id="main-image-mobile"
                  />
                </div>
              </div>
            </div>

            <div className="content-padding">
              <section className="article-text-container">
                <header className="header">
                  <h1 className="heading">{title}</h1>

                  {preparationTimeInMinutes && (
                    <div className="icon-wrap">
                      <img src={timeIcon} alt="ikona času" className="icon" />
                      <span className="icon-text">{`${preparationTimeInMinutes} min`}</span>
                    </div>
                  )}

                  {difficulty && (
                    <div className="icon-wrap">
                      <img
                        src={cakeIcon}
                        alt="ikona lívanců"
                        className="icon"
                      />

                      <span className="icon-text">{`Náročnost: ${translateDifficulty(
                        difficulty
                      )}`}</span>
                    </div>
                  )}
                </header>

                <span className="section-underline"></span>

                {recipeContent.map((contentBlock, i, arr) => (
                  <div key={contentBlock.id}>
                    {contentBlock.ingredients && (
                      <>
                        <section className="resources-container">
                          <div className="small-heading-container">
                            <span className="lateral-underline"></span>
                            <h2 className="small-heading">
                              {contentBlock.ingredientsTitle}
                            </h2>
                          </div>
                          <ul className="list-container">
                            {/* TODO vypsat vsechno, transformovat jen ul li z wis. do ul li jinak vse pod to */}
                            {contentBlock.ingredients
                              .split("</p>")
                              .slice(0, -1)
                              .map((item, j) => {
                                return (
                                  <li className="list-item" key={j}>
                                    {parse(`${item}</p>`)}
                                  </li>
                                );
                              })}
                          </ul>
                        </section>
                        <span className="section-underline"></span>
                      </>
                    )}
                    {contentBlock.contentText && (
                      <>
                        <section className="article-text-container text-container">
                          {parse(contentBlock.contentText)}
                        </section>
                        {!(arr.length - 1 === i) && (
                          <span className="section-underline"></span>
                        )}
                      </>
                    )}
                  </div>
                ))}

                <section>
                  <span className="date-text">
                    {getStringDate(
                      originallyCreatedAt ? originallyCreatedAt : createdAt
                    )}
                  </span>
                </section>
              </section>
            </div>
          </div>

          <div className="left-side-container">
            <section className="article-photo-container desktop runGallery">
              <div className="main-image">
                <div className="image-wrap">
                  <Img
                    fluid={thumbnailImage.childImageSharp.fluid}
                    alt="recept"
                    className="image"
                  />
                </div>
              </div>
              <SRLWrapper>
                <div className="thumbnail-images-wrap">
                  {images.map((img) => (
                    <div key={img.id} className="image-wrap" target="_blank">
                      <a href={img.url} data-attribute="SRL">
                        <img
                          src={img.url}
                          data-src={img.url}
                          className="image"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </SRLWrapper>
            </section>
          </div>

          <span className="site-underline site-underline--bottom">
            Copyright © 2020 Inna Valoušková & Smoothweb.cz
          </span>
        </SimpleReactLightbox>
      </div>
    </Layout>
  );
};

export default RecipeTemplate;

export const query = graphql`
  query RecipeTemplate($id: String!) {
    strapiRecipe(id: { eq: $id }) {
      id
      title
      createdAt
      originallyCreatedAt
      preparationTimeInMinutes
      difficulty
      recipeContent {
        id
        contentText
        ingredientsTitle
        ingredients
      }
      tags {
        id
        Tag
      }
      thumbnailImage {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      images {
        id
        url
      }
    }
  }
`;
